<template>
  <div>
    <b-modal
      ref="modal-quiz-create"
      id="modal-quiz-create"
      title="Créer un Quizz"
    >
      <!-- Media -->

      <b-form>
        <label> Nom </label>
        <b-form-input
          v-model="form.name"
          placeholder="Dupont"
          
        ></b-form-input
        ><br />

        <label> Description </label>
        <b-form-textarea v-model="form.description" placeholder="Dupont">
        </b-form-textarea>
        <br />
      </b-form>

      <template #modal-footer>
        <div class="w-100">

           <b-button
            variant="primary"
            size="sm "
            class="float-right"
            @click="onSubmit"
          >
            Créer
          </b-button>
          
          <b-button
            variant="danger"
            size="sm mr-1"
            class="float-right"
            @click="hideModal"
          >
            Fermer
          </b-button>
         
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BFormTextarea,
  BFormInput,
 
} from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";

export default {
  name: "QuizCreateModal",

  components: {
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormTextarea
  },

  mixins: [Admin],

  data() {
    return {
      show: false,
      form: {
        name: "",
        description: "",
        type : 2
      },
    };
  },

  mounted() {},

  methods: {
   
    async onSubmit() {
      let validate = this.validateForm();
      if (validate) {
        const response = await this.createSurvey(this.form);
        if (response.status == 0) {
          //@dev alert pour dire qu'il y a eu un probleme
          console.log("erreur");
        } else {
          this.$emit("clicked", response.datas);
          this.hideModal()
        }
      } 
    },

    validateForm() {
      let result = true;
      if ( !this.form.name && !this.form.description) {
        result = false;
      }
      return result;
    },

    hideModal() {
      this.form = {};
      this.$refs["modal-quiz-create"].hide();
    },
  },

  computed: {
   
  },
};
</script>

<style>
</style>